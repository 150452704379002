<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="d-flex justify-conetnt-between align-items-center">
      <b-input
        v-model="$v.policyFromId.$model"
        placeholder="Введите id полиса"
        :state="checkErrors ? !$v.policyFromId.$error : null"
      />
      <b-icon
        icon="arrow-right"
        class="mx-3"
      />
      <b-input
        v-model="$v.policyToId.$model"
        placeholder="Введите id полиса"
        :state="checkErrors ? !$v.policyToId.$error : null"
      />
    </div>

    <div class="d-flex justify-content-center mt-4">
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading"
        @click="transferPolicyData"
      >
        Объединить
        <b-spinner
          v-if="isLoading"
          variant="light"
          small
        />
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { api } from '@/helpers/api';
import {
  showErrorCustomMessage,
  showValidationErrorMessage,
  showSuccessCustomMessage,
} from '@/helpers/messages';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'CombinePoliciesModal',
  mixins: [validationMixin],

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Объединение полисов',
    },
  },
  data: () => ({
    isOpen: true,
    isLoading: false,
    policyFromId: null,
    policyToId: null,
    checkErrors: false,
  }),
  validations: {
    policyToId: {
      required,
    },
    policyFromId: {
      required,
    },
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async transferPolicyData() {
      this.$v.$touch();
      this.checkErrors = true;

      if (this.$v.$error) {
        showValidationErrorMessage();
        console.log(this.$v);
        console.log(this.$v.$error);
        return;
      }
      this.isLoading = true;

      try {
        const response = await api.put('/v2/policy/transfer-attachments', {}, {
          params: {
            policyFromId: this.policyFromId,
            policyToId: this.policyToId,
          },
        });

        if (response.status === 200) {
          showSuccessCustomMessage(`Полисы успешно объединены. ID полисов: ${this.policyToId} - ${this.policyFromId}`);
          this.policyToId = null;
          this.policyFromId = null;
          this.checkErrors = false;
          this.onClose();
        }
      } catch (e) {
        console.error(e);
        showErrorCustomMessage('Не удалось объединить полисы');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
